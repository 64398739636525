import Tagify from '@yaireo/tagify';
const _c0 = ["inputRef"];
const _c1 = ["*"];
export { default as Tagify } from '@yaireo/tagify';
import * as i0 from '@angular/core';
import { Injectable, EventEmitter, forwardRef, Component, ViewChild, Input, Output, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, BehaviorSubject, fromEvent, asyncScheduler } from 'rxjs';
import { takeUntil, throttleTime } from 'rxjs/operators';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
class TagifyService {
  constructor() {
    this.tagifyMap = new Map();
  }
  /**
   * Adds a tagify instance, so it is available via service. Used internally.
   */
  add(name, tagify) {
    if (this.tagifyMap.get(name)) {
      console.warn(`There already exists a tagify instance with name ${name}!`);
      return;
    }
    this.tagifyMap.set(name, tagify);
  }
  /**
   * Get tagify instance for full access to tagify API.
   */
  get(name) {
    return this.tagifyMap.get(name);
  }
  /**
   * Removes a tagify instance from service. Used internally.
   */
  remove(name) {
    this.tagifyMap.delete(name);
  }
}
TagifyService.ɵfac = function TagifyService_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TagifyService)();
};
TagifyService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: TagifyService,
  factory: TagifyService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TagifyService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [];
  }, null);
})();
class TagifyComponent {
  constructor(tagifyService, element) {
    this.tagifyService = tagifyService;
    this.element = element;
    this.valueType = 'undefined';
    this.onChange = Function.prototype;
    this.onTouched = Function.prototype;
    this.unsubscribe$ = new Subject();
    this.value$ = new BehaviorSubject(null);
    this.skip = false;
    this.inputClassValue = '';
    this.readonlyValue = false;
    this.settings = {};
    this.name = '';
    this.add = new EventEmitter();
    this.remove = new EventEmitter();
    this.tInput = new EventEmitter();
  }
  set inputClass(v) {
    this.setTagsClass(v);
    this.inputClassValue = v;
  }
  set readonly(v) {
    this.readonlyValue = !!v;
    this.setReadonly();
  }
  get value() {
    return this.valueData;
  }
  set value(v) {
    if (v !== this.valueData) {
      this.valueData = v;
      this.onChange(v);
    }
  }
  ngAfterViewInit() {
    this.settings.callbacks = this.settings.callbacks || {};
    if (!this.settings.callbacks.hasOwnProperty('add')) {
      this.settings.callbacks.add = () => this.add.emit({
        tags: this.tagify.value,
        added: this.tagify.value[this.tagify.value.length - 1]
      });
    }
    if (!this.settings.callbacks.hasOwnProperty('remove')) {
      this.settings.callbacks.remove = () => this.remove.emit(this.tagify.value);
    }
    const innerText = this.element.nativeElement.textContent;
    this.tagify = new Tagify(this.inputRef.nativeElement, this.settings);
    // add to service if name is provided
    if (this.name.length) {
      this.tagifyService.add(this.name, this.tagify);
    }
    this.setReadonly();
    // if there is some text inside component, load this value and skip first change check
    if (innerText.length) {
      this.tagify.loadOriginalValues(innerText);
      this.skip = true;
      setTimeout(() => {
        this.setValue();
      });
    }
    // listen to value changes from outside
    this.value$.pipe(takeUntil(this.unsubscribe$)).subscribe(tags => {
      if (tags === null) {
        return;
      }
      if (this.skip) {
        this.skip = false;
        return;
      }
      if (this.valueType === 'undefined') {
        this.valueType = typeof tags;
      }
      // if string is passed, e.g. via reactive forms
      if (typeof tags === 'string') {
        this.tagify.loadOriginalValues(tags);
        setTimeout(() => {
          this.setValue();
        });
        return;
      }
      // add all tags (already existing tags will be skipped
      this.tagify.addTags(tags, false, true);
      // remove all tags that are not part of value anymore
      this.tagify.value.forEach(v => {
        if (!tags.find(t => t.value === v.value)) {
          // somehow removeTags() with string parameter doesn't always find the tag element
          // this is a workaround for finding the right tag element
          const tagElm = this.tagify.getTagElms().find(el => el.attributes.getNamedItem('value').textContent === v.value);
          this.tagify.removeTags(tagElm);
        }
      });
    });
    // listen to tagify events
    this.tagify.on('input', e => {
      const value = 'value' in e.detail ? e.detail.value : e.detail.textContent;
      this.tInput.emit(value);
      if (this.valueType === 'string' && this.tagify.settings.mode === 'mix') {
        this.value = this.tagify.getMixedTagsAsString();
      }
    });
    fromEvent(this.tagify, 'change').pipe(
    // throttle used to reduce number of value changes when adding/removing a bunch of tags
    throttleTime(0, asyncScheduler, {
      leading: false,
      trailing: true
    }), takeUntil(this.unsubscribe$)).subscribe(() => {
      this.setValue();
    });
    // listen to suggestions updates
    if (this.whitelist) {
      this.whitelist.pipe(takeUntil(this.unsubscribe$)).subscribe(list => {
        this.tagify.settings.whitelist = list;
      });
    }
  }
  writeValue(tags) {
    this.value$.next(tags);
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setValue() {
    if (this.valueType === 'string') {
      if (this.tagify.settings.mode === 'mix') {
        this.value = this.tagify.getMixedTagsAsString();
      } else {
        this.value = this.tagify.DOM.originalInput.value;
      }
    } else {
      this.value = this.tagify.value.slice();
    }
  }
  /**
   * Tagify creates a `tags` element to which the classes of the `input` element are applied.
   * Changes of `inputClass` are applied automatically to the `input` element, but have to be
   * manually applied to the `tags` element.
   */
  setTagsClass(v) {
    const tagsElement = this.element.nativeElement.querySelector('tags');
    if (tagsElement) {
      tagsElement.classList.remove(...this.inputClassValue.split(/\s+/));
      tagsElement.classList.add(...v.split(/\s+/));
    }
  }
  setReadonly() {
    if (this.tagify) {
      this.tagify.setReadonly(this.readonlyValue);
    }
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.tagify.destroy();
    if (this.name.length) {
      this.tagifyService.remove(this.name);
    }
  }
}
TagifyComponent.ɵfac = function TagifyComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TagifyComponent)(i0.ɵɵdirectiveInject(TagifyService), i0.ɵɵdirectiveInject(i0.ElementRef));
};
TagifyComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TagifyComponent,
  selectors: [["tagify"]],
  viewQuery: function TagifyComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 7);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.inputRef = _t.first);
    }
  },
  inputs: {
    settings: "settings",
    name: "name",
    whitelist: "whitelist",
    inputClass: "inputClass",
    readonly: "readonly"
  },
  outputs: {
    add: "add",
    remove: "remove",
    tInput: "tInput"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TagifyComponent),
    multi: true
  }])],
  ngContentSelectors: _c1,
  decls: 4,
  vars: 1,
  consts: [["inputRef", ""], [3, "ngClass"], [2, "display", "none"]],
  template: function TagifyComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵelement(0, "input", 1, 0);
      i0.ɵɵelementStart(2, "span", 2);
      i0.ɵɵprojection(3);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngClass", ctx.inputClassValue);
    }
  },
  dependencies: [i2.NgClass],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TagifyComponent, [{
    type: Component,
    args: [{
      selector: 'tagify',
      template: `<input [ngClass]="inputClassValue" #inputRef/>
    <span style="display: none"><ng-content></ng-content></span>`,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => TagifyComponent),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: TagifyService
    }, {
      type: i0.ElementRef
    }];
  }, {
    inputRef: [{
      type: ViewChild,
      args: ['inputRef', {
        static: true
      }]
    }],
    settings: [{
      type: Input
    }],
    name: [{
      type: Input
    }],
    whitelist: [{
      type: Input
    }],
    inputClass: [{
      type: Input
    }],
    readonly: [{
      type: Input
    }],
    add: [{
      type: Output
    }],
    remove: [{
      type: Output
    }],
    tInput: [{
      type: Output
    }]
  });
})();
class TagifyModule {
  static forRoot() {
    return {
      ngModule: TagifyModule,
      providers: [TagifyService]
    };
  }
}
TagifyModule.ɵfac = function TagifyModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TagifyModule)();
};
TagifyModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TagifyModule
});
TagifyModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [TagifyService],
  imports: [CommonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TagifyModule, [{
    type: NgModule,
    args: [{
      declarations: [TagifyComponent],
      imports: [CommonModule],
      exports: [TagifyComponent],
      providers: [TagifyService]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-tagify
 */

/**
 * Generated bundle index. Do not edit.
 */

export { TagifyComponent, TagifyModule, TagifyService };
