import { CompiereDataFieldType, CompiereDataGridRequestJSON } from '@compiere-ws/models/compiere-data-json';
import { IAutocomplete, isAutocompleteLike } from '@iupics-components/models/autocomplete-interfaces';
import { FilterCtxService } from '@iupics-components/standard/grid/filters/services/filter-ctx.service';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import {
  AppliedItem,
  ColDataRequest,
  ColumnApiService,
  FilterModel,
  FilterModelOperator,
  FilterType,
  GridApiService,
  GridOptionsAppliedItems,
  SortModel,
} from '@iupics/apiz-grid';
import { map, Observable, of, zip } from 'rxjs';

/**
 * Classe utilitaire qui facilite l'utilisation d'apiz-grid pour iupics.
 */
export class ApizGridUtils {
  /** Get applied-items object from a compiere-request */
  public static appliedItemsFromCompiereRequest(
    request: CompiereDataGridRequestJSON,
    api: GridApiService,
    columnApi: ColumnApiService
  ): GridOptionsAppliedItems {
    const appliedItems: GridOptionsAppliedItems = {};
    if (request.filterModel) {
      const filterModel = { ...request.filterModel };
      appliedItems.filters = ApizGridUtils.appliedItemsFromFilterModel(filterModel);
    }

    if (request.sortModel) {
      ApizGridUtils.appliedItemsFromSortModel(request.sortModel, api, columnApi);
    }

    if (request.rowGroupCols) {
      appliedItems.groups = ApizGridUtils.appliedItemsFromRowGroupCols(request.rowGroupCols);
    }

    if (request.valueCols) {
      appliedItems.aggregates = ApizGridUtils.appliedItemsFromValueCols(request.valueCols);
    }
    return appliedItems;
  }

  public static appliedItemsFromFilterModel(filterModel: FilterModel): AppliedItem<'filter'>[] {
    const filters: AppliedItem<'filter'>[] = [];
    for (const col in filterModel) {
      filters.push({
        ...filterModel[col],
        type: 'filter',
        colId: col,
        filterType: filterModel[col].filterType,
        operators: filterModel[col].operators,
        values: filterModel[col].values,
      });
    }
    return filters;
  }

  public static appliedItemsFromSortModel(sortModel: SortModel[], api: GridApiService, columnApi: ColumnApiService) {
    for (const sort of [...sortModel]) {
      api.setSort(columnApi.getColumn(sort.colId), sort.sort, false);
    }
  }

  public static appliedItemsFromRowGroupCols(rowGroupCols: Omit<ColDataRequest, 'colId'>[]): AppliedItem<'group'>[] {
    const groups = rowGroupCols.map<AppliedItem<'group'>>((rc) => ({
      type: 'group',
      colId: rc.id,
      id: rc.id,
      displayName: rc.displayName,
      field: rc.field,
    }));
    return groups;
  }

  public static appliedItemsFromValueCols(valueCols: ColDataRequest[]): AppliedItem<'aggregate'>[] {
    const aggregates = valueCols.map<AppliedItem<'aggregate'>>((vc) => ({
      type: 'aggregate',
      colId: vc.field,
      aggFunc: vc.aggFunc,
      id: vc.field,
    }));
    return aggregates;
  }

  /** Permet de récupérer les displayValue avant d'appliquer les applied-items à Apiz-grid */
  public static updateAppliedItems(
    appliedItems: GridOptionsAppliedItems,
    api: GridApiService,
    filterCtx: FilterCtxService,
    store: DataStoreService
  ) {
    const fieldType = filterCtx.getFieldType();
    const columnType = fieldType;
    const columnNames = filterCtx.provideColumnNames();
    const results$: Observable<{ response: IAutocomplete[]; filterIndex: number; itemIndex: number }>[] = [];
    // récupération des displayValue
    if (appliedItems && appliedItems.filters) {
      for (let i = 0; i < (appliedItems.filters.length ?? 0); i++) {
        const filter = appliedItems.filters[i];

        const column = columnNames.items.find(
          (columnFilter) =>
            columnFilter.id === filter.colId || columnFilter.columnInfo.fieldEntity.ColumnName === filter.colId
        );
        const columnEntity =
          columnType === CompiereDataFieldType.FIELD
            ? column.columnInfo.fieldEntity.field['AD_Field_ID']
            : column.columnInfo.fieldEntity.field['AD_Column_ID'];

        if (filter.filterType === FilterType.SET) {
          if (!Array.isArray(filter.values[0])) filter.values[0] = [filter.values[0]];
          for (let j = 0; j < (filter.values[0].length ?? 0); j++) {
            const value = filter.values[0][j];
            let toPush: Observable<any>;
            if (!isAutocompleteLike(value))
              toPush = store.getAutocompleteDataById(fieldType, columnEntity, value, null, true);
            else toPush = of(value);
            results$.push(toPush.pipe(map((response) => ({ response, filterIndex: i, itemIndex: j }))));
          }
        }
      }
    }

    if (results$.length) {
      zip(...results$).subscribe((results) => {
        for (const result of results) {
          appliedItems.filters[result.filterIndex].values[0][result.itemIndex] = result.response[0];
        }
        api.updateAppliedItems(appliedItems, undefined, true);
      });
    } else {
      api.updateAppliedItems(appliedItems, undefined, true);
    }
  }

  public static cleanDataRequestForCompiereRequest(request: CompiereDataGridRequestJSON): CompiereDataGridRequestJSON {
    // correction filterModel à cause d'apiz-grid
    if (request.filterModel) {
      let fms = structuredClone(request.filterModel);
      const fmKeys = Object.keys(fms);
      for (const fmKey of fmKeys) {
        const fm = fms[fmKey];
        // Fix for between operator
        let { operators } = fm;
        if (
          operators[0] === FilterModelOperator.BETWEEN &&
          (operators.length === 1 || operators[1] !== FilterModelOperator.BETWEEN)
        ) {
          fm.operators.push(FilterModelOperator.BETWEEN);
        }

        for (let i = 0; i < fm.values.length; i++) {
          const value = fm.values[i];
          if (Array.isArray(value)) fm.values[i] = value.map((item) => (item instanceof Object ? item.id : item));
          else if (value instanceof Object) fm.values[i] = value.id;
        }

        fms[fmKey] = fm;
      }
      request.filterModel = fms;
    } else {
      request.filterModel = {};
    }

    // Correction valueCols à cause d'apiz-grid
    if (request.valueCols?.length) {
      for (const valueCol of request.valueCols) {
        delete valueCol.colId;
      }
    }
    return request;
  }
}
